import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'fr',
  resources: {
     fr: {
            common: common_fr               // 'common' is our custom namespace
        },
      en: {
            common: common_en
        },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <I18nextProvider i18n={i18next}>
          <Routes>
          <Route path='/*' element={<App/> }/>
        </Routes>
        </I18nextProvider>
       
    </AuthProvider>
    </BrowserRouter>
   
  </React.StrictMode>
);

