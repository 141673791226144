import React,{useState} from 'react';
import "../assets/styles/admin.css";
import { RiArrowDropDownLine,RiArrowDropLeftLine,RiArrowDropRightLine,RiArrowDropUpLine } from 'react-icons/ri';
import {useTranslation} from "react-i18next";

const AdminSidebar = (props) => {
  const { onClick3, onClick1, onClick2, onClick4,
  onClick5,onClick6,onClick7,onClick8, param1
  } = props
  const [ show, setShow ] = useState(true);
    const [ show1, setShow1 ] = useState(true);
  const [ showSideBar, setShowSideBar ] = useState(true);
    const {t, i18n} = useTranslation('common');
  const clickIcons = () => setShow(!show);
  const clickIcons2 = () => setShow1(!show1);
  const clickIcons3 = () => setShowSideBar(!showSideBar);
  
  return (
    <div className='admin__sidebarContainer'>
        {
          showSideBar ? (
          <>
    
            <div className="admin__sidebarContent">
              <div className="admin__sidebarheader">
             <h3>{ t('adm.title')}</h3>
        
      </div>
        <RiArrowDropLeftLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons3} className='side__icon'/>

                <div className="all__bikes">
       
          {
            show ?
            (
                <div className="show">
                            <span>
                          <button onClick={onClick3} className='button'>{ t('adm.all')}</button>
                            <RiArrowDropUpLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons}/>

                            </span>
                
           
         
            
                        <div className="all__bikesSection">
                          {onClick8 && (
                              <button className='button' onClick={onClick8}>{ t('adm.ad')}</button>
                          )}
                          {
                            onClick2 && (
                                <button className='button' onClick={onClick2}>{ t('adm.hd')}</button>
                            )
                          }
                          {
                            onClick6 && (
                              <button className='button' onClick={onClick6}>{ t('adm.cust')}</button>
                            )
                           }
                          
                            
                          <button className='button' onClick={onClick1}>{ t('adm.ena')}</button>
                          <button className='button' onClick={onClick4}> { t('adm.dis')}</button>
              
              </div>
              </div>
           
              ) : (
                <span>
                  <button className='button' onClick={onClick3}>{ t('adm.all')}</button>
                    <RiArrowDropDownLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons}/>
                </span>
            

            )
          }
              </div>
           
        
        <div className="profile">
       
          {
            show1 ?
            (
                <div className="show">
                        <span>
                    <button className='button' onClick={onClick5}>{ t('adm.prof')}</button>
         
              <RiArrowDropUpLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons2}/>
          </span>
            
              <div className="all__bikesSection">
                          <button className='button'>{ t('adm.sett')}</button>
                         <button className='button'onClick={onClick7}>{ t('adm.out')}</button>
             
              </div>
              </div>
           
              ) : (
                      <span>
                      <button className='button'>{ t('adm.prof')}</button>
               
                    <RiArrowDropDownLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons2}/>
                </span>
            

            )
          }
              </div>
            </div>
            </>
          ) : (
              <div className="hidden__sidebar">
                    <RiArrowDropRightLine size={35} style={{ color: '#ffffff' }} onClick={clickIcons3} className='side__icon'/>

              </div>
          )
        
        }
    </div>
  )
}

export default AdminSidebar