
import React,{useState,useEffect,useRef} from 'react';
import { AdminHeader, AdminSidebar, Footer} from "../Components";
import { Enabled4 ,Disabled4,AllUsers4, Search4} from '../Components/DetailHead';
import { resetUserSession,getTerm,setTerm1,getOneUser } from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import "../assets/styles/detailpage.css";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap ,Marker,Popup,Map} from 'react-leaflet';
import L from "leaflet";
import axios from 'axios';

const center = [ 4.0511, 9.7679 ];
const markerIcon = new L.icon({
    iconUrl: require("../assets/images/bikeIcon.png"),
    iconSize: [ 35, 45 ],
    iconAnchor:[25,16]
})

const DetailHead = () => {
    const mapRef = useRef(null)
    const user = getOneUser()
    const [ term, setTerm ] = useState()
  const [ enabled, setEnabled ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [position, setPosition] = useState([ 4.0511, 9.7679])
  const [ search, setSearch ] = useState(false)
    const [ allUsers, setAllUsers ] = useState(true);
    useEffect(() => {
        
        const locateBike = () => {
            const requestUrl = "https://api.wheretheiss.at/v1/satellites/25544"
            axios.get(requestUrl).then(response => {
                setPosition([response.data.latitude,response.data.longitude])
              })
    
        }
        const timeInterval = setInterval(() => {
            locateBike()
        },5000)
      return () => clearInterval(timeInterval)
    },[])
    const navigate = useNavigate();
    const{current} = mapRef
    const clicktry = () => {
        const { leafletElement: map } = current
        map.setView([position[0],position[1]],13)
    }

     const previousTerm = getTerm()
  const onclick = () => {
    setEnabled(false)
    setDisabled(false)
    setSearch(false) 
    setAllUsers(true)
   }
  const searchSubmit = () => {
       setDisabled(false)
    setEnabled(false)
    setSearch(true)
    setAllUsers(false)
    setTerm1(term)
    
  } 

  const onclick4 = () => {
    setDisabled(true)
    setEnabled(false)
    setSearch(false)
    setAllUsers(false)
  }
  const onclick01 = () => {
    setEnabled(true)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
  
  }
       const logoutButton = () => {
    resetUserSession();
    navigate('/')
    }
  
  return (
  
      <div className='detail__page'>
          <div className="detail__header">
              <AdminHeader
               term={term}
          setTerm={setTerm}
          searchSubmit={searchSubmit}
              />
          </div> 
          <div className="detail__body">
              <div className="detail__sidebar">
                  <AdminSidebar
                    onClick3={onclick}
            onClick4={onclick4}
            onClick1={onclick01}
            onClick7 = {logoutButton}
                  />
              </div>
              <div className="detail__content">
                
                  <div className="detail__contentBody">
                      <div className="bike__list">
                          {
                              allUsers && (
                                  <AllUsers4/>
                              )
                          }
                          {enabled && (
                              <Enabled4/>
                          )}
                          {
                              disabled && (
                                  <Disabled4/>
                              )
                          }
                            {
          (search && term !== "") &&(
            <Search4
            term={term}
            />
          ) 
        }
        {
           search  && term === "" && (
            <Search4
              term={previousTerm}
            />
          )
        }
                  </div>
                  <div className="detail__map" id='myMap'>
                          <MapContainer
                              center={center}
                              id='myMap'
                                 ref={mapRef}
                           
      zoom={10}
      style={{ width: '80%', height: '100%' , alignItems:'center', margin:'auto',marginTop:'-40px',borderRadius:'30px',border:'1px solid #707070' }}
                          >
                              <TileLayer
                                  
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />

                              <Marker position={[position[0],position[1]]}
                              icon={markerIcon}
                              >
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer>
                </div>
                  </div>
              
              </div>
          </div>
<Footer/>
    </div>
  )
}

export default DetailHead