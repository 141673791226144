import React, { useState } from 'react';
import axios from '../api/axios';
import "../assets/styles/change.css"
import { Footer } from '../Components';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const { t } = useTranslation('common');
    const [ email, setEmail ] = useState();
    const [ message,setMessage ] = useState("");
   
    const onSubmit = () => {
        const requestBody = {
            email:email
        }
        const requestUrl = '/auth/forgot-password'
        axios.post(requestUrl, requestBody).then(response => {
            console.log(response)
            setMessage(t('adm.ls'))
        }).catch(errors => {
            if (errors.response.status === 401 || errors.response.status === 403) {
            setMessage(t('error.noU'))
            } else {
                setMessage(t('adm.erro'))
            }
        })
    }
    const formSubmit = (e) => {
        e.preventDefault();
}
  return (
      <div className='reset__password'>
          <div className="reset__content">
              <h3>{ t('adm.fp')}</h3>
              <form onSubmit={formSubmit}>
                   <input type="text" placeholder={t('adm.email')} defaultValue={email} onChange={e=> setEmail(e.target.value) }/>
                  <button type='submit' onClick={onSubmit}>{ t('adm.sub')}</button>
          {message && (
              <p>{ message}</p>
          )}
              </form>
       
          </div>
      <Footer/>
    </div>
  )
}

export default ForgotPassword