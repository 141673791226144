import React,{useState} from 'react';
import "../assets/styles/contact.css";
import { useForm } from "react-hook-form";
import axios from '../api/axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const contactUrl = "/contact/";

const schema = yup.object({
    firstName: yup.string().required(),
    lastName:yup.string(),
    email: yup.string().email().required(),
    subject:yup.string().required(),
    message: yup.string().required()
}).required();

const ContactUs = () => {
    const [ message, setMessage ] = useState("");
    const {t} = useTranslation('common');
      const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
      });
    const contactSubmit = (data) => {
        const { firstName, lastName, email, message,subject } = data;
         const requestBody = {
            email: email,
             firstName: firstName,
             lastName: lastName,
             subject:subject,
            message:message
        }
        axios.post(contactUrl, requestBody).then(response => {
            setMessage(t('home.contact.succ'));
        }).catch(errors => {
            
        })
    }
  return (
      <div className="contact__us" id='contact'>
          <div className="contact__usHeader">
             <h1>{ t('home.contact.title')}</h1> 
          <p>{ t('home.contact.tagline')}</p>
          </div>
          <div className="contact__name">
              <div className="contact__nameOne">
                  <label htmlFor=""> { t('home.contact.1')}</label>
                  <input type="text"
                  {...register("firstName")}
                  />
              </div>
              <div className="contact__nameOne">
                  <label htmlFor=""> { t('home.contact.2')}</label>
                  <input type="text"
                  {...register("lastName")}/>
              </div>
            
          </div>
          <div className="contact__email">
              <label htmlFor="">{ t('home.contact.email')}</label>
              <input type="text"
                {...register("email")}
              />
          </div>
           <div className="contact__email">
              <label htmlFor="">{ t('home.contact.subj')}</label>
              <input type="text"
                {...register("subject")}
              />
          </div>
          <div className="contact__message">
              <label htmlFor="">{ t('home.contact.mess')}</label>
              <textarea name="" id="" cols="30" rows="10"
              {...register("message")}
              ></textarea>
          </div>
          <button type='submit' onClick={handleSubmit(contactSubmit)}>{t('home.contact.btntxt')}</button>
          {message && (
              <p>{ message}</p>
          )}
      </div>
  )
}

export default ContactUs