import React,{useState} from 'react';
import "../assets/styles/createaccount.css";
import { useForm } from "react-hook-form";
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { getUser, getToken, resetUserSession } from '../services/AuthService';
import { useTranslation } from 'react-i18next';
import { Logo } from "../assets/images";

const CreateAccount = () => {
  const { t } = useTranslation('common');
  const [ error2, setError2 ] = useState();
  const navigate = useNavigate();
  const token = getToken();
  const user = getUser();
  const { handleSubmit, register, getValues, formState: { errors } } = useForm();
 
   
  const onSubmit = data => {
    const { fullName, email, username, group, number,
      address, assetId, syndicate, password} = data
    const requestBody = {
      name:fullName,
      email,
      username,
      group,
      number,
      address,
      assetId,
      syndicate,
      password
    }
    const requestUrl = `/auth/signup/admin`
   const headers = {
    'x-auth-token': `${token}`
    }
    if (user) {
      axios.post(requestUrl, requestBody, { headers }).then(response => {
        navigate('/comfirmation-page')
      }).catch(errors => {
        if (errors.response.status === 401 || errors.response.status === 403) {
          if (errors.response.data.errors[ 0 ].msg === "Invalid Token" || errors.response.data.errors[ 0 ].msg === "No token found"  ) {
            navigate('/login')
          } else if (errors.response.data.errors[ 0 ].msg === "Access Denied") {
            navigate('/unauthorized')
          } else if (errors.response.data.errors[ 0 ].msg === "All fields required") {
            setError2(t('error.FeA'))
          } else if (errors.response.data.errors[ 0 ].msg === "asset Id already exist") {
            setError2(t('error.AssE'))
          }else if (errors.response.data.errors[ 0 ].msg === "email address already exist") {
            setError2(t('error.Eme'))
          }else if (errors.response.data.errors[ 0 ].msg === "Syndicate Head already exist") {
            setError2(t('error.synE'))
          }else {
      
            setError2(t('error.seE'))
          }
       
        } else {
          setError2(t('adm.erro'))
         }
      })
    }
  }
   const logout = () => {
    resetUserSession()
    navigate('/')
  }
  return (
    <>
      <div className="create__header">
        <img src={Logo} alt="" onClick={()=>{navigate("/")}} className="logo"/>
        <div className="user__name">
          <strong  className="admin__home" onClick={()=>{navigate("/admin")}}>{t('adm.adH')}</strong>
          <h3 onClick={logout}>{ t('home.navbar.logout')}</h3>
        </div>
      </div>
    <div className="create__account">
        
         
          <form className="create__accountBody" onSubmit={handleSubmit(onSubmit)}>
              <div className="left__sideInputs">
            <h2>{ t('create.title')}</h2>
          <input type="text" placeholder={t('create.name')}
            {...register("fullName", {
            //required: "Required",
          validate: value => value !== "admin" || `${t('error.una')}`
        })}
          />
          {errors.fullName &&
            <strong> {errors.fullName.message}</strong>
           }
          
          <input placeholder={t('login.email')}
            type="email"
        {...register("email", {
          //required: "Required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: `${t('error.inv')}`
          }
        })}
          />
          {errors.email &&
            <strong>{errors.email.message } </strong>
            }
          <input type="text" placeholder={t('create.user')}
            {...register("username", {
            // required: true,
          validate: value => value !== "admin" || `${t('error.una')}`
        })}
          />
          {errors.username &&
            <strong>{  errors.username.message } </strong>
          }
          <select 
          id="groups"
             {...register("group", {
             //required: true,
        })}
          >
              <option value="gggg">--{ t("create.sel")}--</option>
            <option value="Admin">{ t("adm.ad")}</option>
            <option value="Syndicate-Head" >{ t("adm.hd")}</option>
             <option value="Customer">{ t("adm.cust")}</option>
          </select>
          {/* <input type="text" placeholder='Group'
            {...register("group", {
             required: true,
        })}
          /> */}
          {errors.group &&
            <strong>{  errors.group.message } </strong>
          }
               
            <input type="text" placeholder={ t("create.tel")}
              {...register("number", {
             //required: true,
                      minLength: {
                  value: 9,
                message:`${t('error.cret1')}`
                },
        maxLength: {
                  value: 9,
                message:`${t('error.cret1')}`
                }
        })}
            />
            {errors.number &&
              <strong>{   errors.number.message} </strong>
            }
                 
         
              </div>
        <div className="right__sideInputs">
    
                 <input type="text" placeholder={ t("adm.id")}
             {...register("assetId", {
             //required: true,
                   minLength: {
                 value: 10,
                 message:`${t('error.cret2')}`
               },
        maxLength: {
                 value: 10,
                 message:`${t('error.cret2')}`
               }
        })}
          />
       
       
          {errors.assetId &&
            <strong>{ errors.assetId.message } </strong>
           }
          <input type="text" placeholder={t('adm.syn')}
             {...register("syndicate", {
             //required: true,
        })}
          />
          {errors.syndicate &&
            <strong>{ errors.syndicate.message } </strong>
          }
            <input type="text" placeholder={ t("adm.address")}
             {...register("address", {
             //required: true,
        })}
          />
          {errors.address &&
            <strong>{ errors.address.message } </strong>
           }
          <input type="text" placeholder={ t("login.password")}
             {...register("password", {
             //required: true,
                    minLength: {
                 value: 8,
                 message:`${t('error.cret3')}`
               },
        maxLength: {
                 value: 15,
                 message:`${t('error.cret3')}`
               }
        })}
          />
         
          {errors.password &&
            <strong>{errors.password.message } </strong>
            }
          <input type="text" placeholder={ t("create.RptPwd")}
             {...register("repeatPwd", {
             //required: true,
           validate: value => value === getValues().password || `${ t("error.nMa")}`
        })}
          />
          {errors.repeatPwd &&
            <strong>{ errors.repeatPwd.message } </strong>
           }
          <button type='submit'>{ t("create.btn")}</button>
          {
            error2 && (
              <strong>{ error2}</strong>
            )
          }
              </div>
          </form>
  </div>
    </>
    
  )
}

export default CreateAccount