import React from 'react';
import "../assets/styles/about.css";
import { lady1, lady2, lady3, town,bikes } from "../assets/images";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const About = () => {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate()
    return (
        <>
             <div className="about" id='About'>
          <div className="about__left">
              <h1>{ t('home.about.title')}</h1>
              <p>{ t('home.about.body')}</p>
                    <button onClick={()=>{ navigate('/download-app')}}>{t('download.title') }</button>
          </div>
          <div className="about__right">
              <img src={bikes} alt="" className='image__4' />
              <img src={lady1} alt="" className='image__1' />
              <img src={lady2} alt="" className='image__2' />
              <img src={lady3} alt="" className='image__3'/>
          </div>
      </div>
     <div className="after__about">
            <h1>{ t('home.about.track')}</h1> 
      </div>
      </>
     
  )
}

export default About