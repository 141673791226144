import React from 'react'
import "../assets/styles/detailpage.css"
import { getOneUser } from '../services/AuthService'
const BikeInfo = ({ name, syndicate, assetId, email, locate }) => {
    const user = getOneUser()
  return (
      <div className='bike__info' onClick={locate}>
          <div className="bike__infoBody">
          <div className="name">
                  <h5>{ name}</h5>
          </div>
          <div className="more__info">
              <p>{syndicate}</p>
              <p>{assetId}</p>
                <p>{ email}</p>
</div>
          </div>

    </div>
  )
}

export default BikeInfo