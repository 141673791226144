import React,{useEffect,useRef,useState} from 'react';
import "../assets/styles/herosection.css";
import { apple, google, bike, app_view, tracker } from "../assets/images"
import {useTranslation} from "react-i18next";

const HeroSection = () => {
    const timeout = useRef(null);
     const {t, i18n} = useTranslation('common');
    const [ current, setCurrent ] = useState(0);
    const length = 3;
    useEffect(() => {
        const next = () => {
            setCurrent((current) => (current === length - 1 ? 0 : current + 1));
          
        };
        timeout.current = setTimeout(next, 3000);
        return function () {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [ current, length ]);
    const slideInfo = [
        {
            "image": `${bike}`,
            "text": `${ t('home.hero.1')}`,
            "number": "01",
            
            
        },
          {
            "image": `${tracker}`,
            "text": `${ t('home.hero.2')}`,
            "number":"02"
        },
            {
            "image": `${app_view}`,
                "text": `${ t('home.hero.3')}`,
            "number":"03"
        },
    ]
      const moveDot = index => {
        setCurrent(index)
    }
  return (
      <div className="hero__section">
          <div className="hero__sectionBody">
              <div className="hero__sectionSlide">
                  {slideInfo.map((slide, index) => {
                      return (
                          <div  className = " slideShow"key={index}>
                              {
                                  index === current && (
                                      <>
                                          <div className="hero__sectionSlideInfo">
                                              <div className="slide__text">
        <p>/ { slide.number}</p>
                    <h3>{ slide.text}</h3>
                                              </div>
                    
                  </div>
                  <img src={slide.image} alt="" /> 
                                      </>
                      
                                  )
                              }
                   
                          </div>
                  
                      )
                  })}              
                  
                              <div className="container__dots">
                {Array.from({length: 3}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index)}
                    className={current === index  ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
              </div>
    
              <div className="hero__sectionInfo">
                  <h1>{ t('home.hero.tagHead')}</h1>
                  <p>{ t('home.hero.tagTitle')}</p>
                  <div className="hero__sectionInfoButtons">
                      <button className='one'><img src={google} alt="" /></button>
                       <button className='two'><img src={apple} alt="" /></button>
                </div>
              </div>
          </div>
         
    </div>
  )
}

export default HeroSection