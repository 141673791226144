import React from 'react';
import "../assets/styles/herosection.css";
import { apple, google } from "../assets/images";
import {useTranslation} from "react-i18next";

const DownloadApp = () => {
   const {t, i18n} = useTranslation('common');
    return (
      <div className="hero__sectionBody">
                  <div className="hero__sectionInfo">
                  <h1>{ t('download.title')}</h1>
                  <p>{ t('download.tagline')}</p>
                  <div className="hero__sectionInfoButtons">
                      <button className='one'><img src={google} alt="" /></button>
                       <button className='two'><img src={apple} alt="" /></button>
                </div>
              </div>
      </div>

  )
}

export default DownloadApp