import React,{useState} from 'react';
import "../assets/styles/verify.css";
//import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import { useNavigate } from "react-router-dom";
import { setUserSession } from '../services/AuthService';
import { getUser } from '../services/AuthService';
import { useTranslation } from "react-i18next";

const verifyUrl = "/auth/2fa/validate";

const VerifyAdmin = () => {
  //const { setAuth, auth } = useAuth();
  const { t } = useTranslation('common');
  const user = getUser()
  const [ token, setToken ] = useState();
  const navigate = useNavigate();
  const onSubmit = () => {
    const requestBody = {
      token1: token,
      email: user?.email,
    }
    axios.post(verifyUrl, requestBody).then(response => {
      console.log(response);
      // const groups = [];
      // groups.push(response.data.user.group);
      // setAuth({ ...response.data, groups });
      setUserSession(response.data.user,response.data.token)
      navigate('/admin')
    })
  }

  return (
      <div className="verify__page">
      <h1> {t('adm.2f') }</h1>
          <div className="verify__pageBody">

        <input type="text"  value={user?.email} readOnly/>
        <input type="text" placeholder={t('adm.iT')} defaultValue={token}
        onChange={ e=> setToken(e.target.value)}
        />
          <button type='submit' onClick={onSubmit}>{t('adm.sub')}</button>
          </div>
        
   </div>
  )
}

export default VerifyAdmin