import React from 'react';
import { CreateAccountHead, Footer } from "../Components";
import "../assets/styles/createaccount.css"

const CreateHead = () => {
  return (
    <div className='create__admin'>
      <div className="create__adminBody">
        <CreateAccountHead/>
      </div>
      <div className="create__footer">
        <Footer/>
      </div>

    </div>
  )
}

export default CreateHead