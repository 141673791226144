import React,{useState,useEffect} from 'react';
import axios from "../../api/axios";
import { getUser,getToken } from '../../services/AuthService';
import BikeDetails from "../BikeDetails";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Loading from '../loading';


function Search({term}) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [ page, setPage ] = useState(1);
  const [ data, setData ] = useState();
  const [ pageTitle, setPageTitle ] = useState("")
  const [pageCount, setPageCount] = useState()
  const [ loading, setLoading ] = useState(true);
  const user = getUser();
  const token = getToken();
 
  useEffect(() => {
const searchSubmit = () => {
   const requestUrl = `/profiles/search-head/${term}?page=${page}&limit=12`;
  const headers = {
    'x-auth-token': `${token}`
    }

  if (user) {
  
    axios.get(requestUrl, { headers }).then(response => {
       setPageTitle( t('adm.rest')+` ${term}`)
      setData(response.data)
        setLoading(false)
         setPageCount(response.data.pageCount.pageCount)
     
    }).catch(errors => {
      setLoading(false)
      setData("")
       if (errors?.response?.status === 401 || errors?.response?.status === 403) {
         if ( errors.response.data.errors[0].msg === "Invalid Token") {
           navigate('/login')
         } else {
           setPageTitle(t('error.noU'))
    
         }
   
        
            } 
    })
   
  }
    } 
  
    searchSubmit()
      setLoading(false)
  }, [ page,term ])

  const pageNumbers = [];

   for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }
  const paginate = pageNumber => {
  
    setPage(pageNumber)
  };
   if (loading) {
     return (
      <div className='admin__loading2'>
      <Loading />

      </div>)
  }
  return (
    <div className="admin__content">
      {loading ? (<p>Loading</p>) : (
        <>
         <div className="create__user">
          <button onClick={()=>{navigate('/create-head')}} >{t('adm.create')}</button>
        </div>
          {pageTitle && (
            <div className='content__header'>
            <h3>{pageTitle}</h3>
            </div >
      
          )}
          <div className="admin__contentRow">
            {
              data&& (
                 data?.results?.map((item, index) => {
                return (
                  <BikeDetails
                    username={item.username}
                    address={item.address}
                    syndicate={item.syndicate}
                    email={item.email}
                    assetId={item.assetId}
                       fullName={item.fullName}
                    userStatus={item.userStatus}
                    group={item.group}
                    number={item.number}
                    date={item?.date }
                  />
                )
              })
              ) 
             
          }
          </div>
          <div className="page__number">
               <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <p onClick={() => paginate(number)} className='page-link'>
              {number}
            </p>
          </li>
        ))}
      </ul>
    </nav>
          </div>
        </>
      )}
          
        </div>
 
  )
}

export default Search