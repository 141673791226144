import React, { useEffect, useState} from 'react';
import { FaSearch } from 'react-icons/fa';
import "../assets/styles/admin.css";
import { Button } from "@mui/material";
import { getUser } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Logo} from "../assets/images"


function AdminHeader({ searchSubmit, term, setTerm }) {
  const [ title, setTitle ] = useState()
  const {t, i18n} = useTranslation('common');
  const navigate = useNavigate();
  const user = getUser();
  const adminName = user?.fullName;
  const formSubmit = (e) => {
    e.preventDefault()

  }

  useEffect(() => {
    if (user.group === "admin") {
      setTitle("Admin")
    } else {
      setTitle("Head")
    }
  },[])
  return (

      <div className="admin__headerContainer">
      <div className="admin__headerLogo">
        <img src={Logo} alt=" Data Corp Inc." onClick={() => { navigate("/") }} className="logo__a" />
      </div>
      <form className="admin__headerSearch" onSubmit={formSubmit}>
        <input type="text" placeholder={ t('adm.search')} defaultValue={term} onChange={ e=> setTerm(e.target.value)}/>
        <Button type='submit' onClick={searchSubmit}><FaSearch size={20} style={{ color: '#ffffff' }}/></Button>
      </form>
          <div className="admin__headerProfile">
              <span>
                  <small>{ adminName}</small>
          <strong onClick={() => {
            user.group === "admin"? navigate("/admin"): navigate("/syndicate-head")
               }}>{ title}</strong>
              </span>
        </div>
      </div>
      

  )
}

export default AdminHeader