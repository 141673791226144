import React from 'react';
import "../assets/styles/homepage.css";
import { HeroSection, Navbar ,About,HowTo, Contact,Footer} from "../Components";


function HomePage() {
  return (
    <div className='home_page'>
      <div className="home__pageHeader">
    
            <Navbar />
       
        
          <HeroSection/>
       </div>
      <div className="home__pageBody">
          <About />
               <HowTo />
             <Contact/>
      </div>
      <Footer/>
    </div>
  )
}

export default HomePage