import React,{useState,useEffect} from 'react';
import { getUser } from "../services/AuthService";
import star from "../assets/images/stars.png";
import check from "../assets/images/check-mark.png";
import "../assets/styles/comfirmation.css";
import { Footer } from "../Components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Logo} from "../assets/images"

function ComfirmationPage() {
  const { t } = useTranslation('common')
  const [ name, setName ] = useState("");
  const[admin,setAdmin] = useState(false)
  const navigate = useNavigate();
  const user = getUser();
  useEffect(() => {
    const nameFunc = () => {
       if (user.group === "admin") {
         setName(t('adm.adH'))
         setAdmin(true)
       } else {
         setName(t('adm.hH'))
         setAdmin(false)
  }
    }
    nameFunc()
  },[])
 

  return (
    <div className='comfirmation__page'>
      <div className="body">
        <div className="comfirmation__header">
            <img src={Logo} alt=" Data Corp Inc." onClick={()=>{navigate("/")}} className="logo"/>      
          {
            admin ? (
              <div className='admin'>
                 <h3 onClick={() => { navigate("/admin") }}>{name}</h3>
          <h3 onClick={()=>{navigate("/create-admin")}}>{t('adm.create')}</h3>
              </div>
            ) : (
                  <div className='admin'>
                 <h3 onClick={() => { navigate("/syndicate-head") }}>{name}</h3>
          <h3 >{t('adm.create')}</h3>
              </div>
            )
          }
         
      </div>
      <div className="comfirmation__body">
        <img src={star} alt="" />
        <h4>{t('comf.mess')}</h4>
        <img src={check} alt="" />
      </div>
      </div>
      <div className="footer">
              <Footer/>
     </div>

    </div>
  )
}

export default ComfirmationPage