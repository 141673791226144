import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import "../assets/styles/navbar.css";
import { getUser, resetUserSession } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import {Logo} from "../assets/images"

function Navbar() {
  const [ click, setClick ] = useState(false);
  const {t, i18n} = useTranslation('common');
  const navigate = useNavigate();
  // change nav color on scroll
  const [ color, setColor ] = useState(false);
  const [ buttonName, setButtonName ] = useState()
  const[loggedIn, setLoggedIn] = useState()
  const user = getUser()
  const changeColor = () => {
    if (window.scrollY >= 40) {
      setColor(true);
    } else {
      setColor(false);
    }
   
  }
  // useEffect(() => {
  //   if (user) {
  //     setButtonName(t('home.navbar.logout'))
  //     setLoggedIn(true)
  //   } else {
  //     setButtonName(t('home.navbar.login'))
  //     setLoggedIn(false)
  //   }
  // },[loggedIn])
  const logoutSubmit = () => {
    resetUserSession()
    setLoggedIn(false)
  }
  window.addEventListener('scroll', changeColor);
  const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)
  return (
    <div className={color? 'header__bg header':'header'}>
      <nav className='navbar'>
        <img src={Logo} alt=" Data Corp Inc." onClick={() => { navigate("/") }} className="logo__h" />
        <div className='navbar__mobileView' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

        </div>
            <ul className={click ? "nav__menu active" : "nav__menu"}>
                    <li className='nav__item'>
            <a href='/' onClick={closeMenu}>{ t('home.navbar.home')}</a>
                    </li>
                    <li className='nav__item'>
                        <a href='#About' onClick={closeMenu}>{ t('home.navbar.about')}</a>
                    </li>
                    <li className='nav__item'>
                        <a href='#howTo' onClick={closeMenu}>{ t('home.navbar.how')}</a>
                    </li>
                    <li className='nav__item'>
                        <a href='#contact' onClick={closeMenu}>{ t('home.navbar.contact')}</a>
          </li>
          <li>
         
            <button className='nav__item ' onClick={ !user?
              () => navigate('/login') : logoutSubmit}>{
                user? `${t('home.navbar.logout')}`: `${t('home.navbar.login')}`
              }</button>
              
          </li>
        
                </ul>
   </nav>
       
      </div>
  )
}

export default Navbar