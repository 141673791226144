import React,{useState} from 'react';
import { resetUserSession, getTerm ,setTerm1} from '../services/AuthService';
import { AdminHeader, AdminSidebar } from "../Components";
import { Search2,AllUsers2,Enabled2,Disabled2} from "../Components/Heads"
import { useNavigate } from 'react-router-dom';


function SynHead() {
  const navigate = useNavigate();
  const [ term, setTerm ] = useState("");
  const [ enabled, setEnabled ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);
  const [ search, setSearch ] = useState(false)
  const [ allUsers, setAllUsers ] = useState(true);
  const previousTerm = getTerm()
  const onclick = () => {
    setEnabled(false)
    setDisabled(false)
    setSearch(false) 
    setAllUsers(true)
   }
  const searchSubmit = () => {
       setDisabled(false)
    setEnabled(false)
    setSearch(true)
    setAllUsers(false)
    setTerm1(term)
    
  } 
 

  const onclick4 = () => {
    setDisabled(true)
    setEnabled(false)
    setSearch(false)
    setAllUsers(false)
  }
  const onclick01 = () => {
    setEnabled(true)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
  
  }
    const logoutButton = () => {
    resetUserSession();
    navigate('/')
  }
console.log(term)
  return (
    <div className='admin__page'>
      <div className="admin__header">
        <AdminHeader
          term={term}
          setTerm={setTerm}
          searchSubmit={searchSubmit}
        />
      </div>
      <div className="admin__body">
       
        <div className="admin__sidebar">
          <AdminSidebar
            onClick3={onclick}
            onClick4={onclick4}
            onClick1={onclick01}
            onClick7 = {logoutButton}
          />
        </div>
        {
          enabled && (
            <Enabled2/>
          )
        }
        {
          disabled && (
            <Disabled2/>
          )
        }
        {
          (search && term !== "") &&(
            <Search2
            term={term}
            />
          ) 
        }
        {
           search  && term === "" && (
            <Search2
              term={previousTerm}
            />
          )
        }
      
        {
          allUsers && (
            <AllUsers2/>
          )
        }
      </div>
  </div>
  )
}

export default SynHead;