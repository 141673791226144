import React from 'react';
import { CreateAccount, Footer } from "../Components";
import "../assets/styles/createaccount.css"

const CreateAdmin = () => {
  return (
    <div className='create__admin'>
      <div className="create__adminBody">
     <CreateAccount />
      </div>
      <div className="create__footer">
           <Footer/>
     </div>
   
    </div>
  )
}

export default CreateAdmin