module.exports = {
    getUser: function () {
        const user = sessionStorage.getItem('user');
        if (user ==='' || !user) {
            return null;
        } else {
            return JSON.parse(user);
        }
    },
    getToken: function () {
        return sessionStorage.getItem('token');
    },
    setUserSession: function (user,token) {
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', token);
    },
    resetUserSession: function () {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('term');
        sessionStorage.removeItem('oneUser')
    },
    setOneUser: function (user) {
        sessionStorage.setItem('oneUser',JSON.stringify(user))
    },
    getOneUser: function () {
         const user = sessionStorage.getItem('oneUser');
         if (user ==='' || !user) {
            return null;
        } else {
            return JSON.parse(user);
        }
    },
    removeUser: function () {
        sessionStorage.removeItem('oneUser');
    },
    setTerm1: function (term) {
        sessionStorage.setItem('term',term)
    },
    getTerm: function () {
        return sessionStorage.getItem('term')
    },
    searchHistory: function (terms) {
        sessionStorage.setItem('searchterms',terms)
    },
    getSearchHistory: function () {
        sessionStorage.getItem('searchterms')
    }
}