import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import axios from '../api/axios';
import "../assets/styles/edit.css";
import { getOneUser,removeUser,getToken ,resetUserSession} from '../services/AuthService';
import { useTranslation } from 'react-i18next';
import { Logo } from "../assets/images";

const EditHead = () => {
  const{t} = useTranslation('common')
  const [ character, setCharacter ] = useState("");
  const [ presentValue, setPresentValue ] = useState("Character Value");
  const [ input, setInput ] = useState();
  const [ error, setError ] = useState();
  const navigate = useNavigate();
  const token = getToken();
  const user = getOneUser()
  const onclickOne = () => {
     setCharacter("fullName")
    setPresentValue(user.fullName)
  }
  const onclickTwo = () => {
     setCharacter("email")
    setPresentValue(user.email)
  }
   const onclickThree = () => {
     setCharacter("username")
    setPresentValue(user.username)
  }
   const onclick4 = () => {
     setCharacter("address")
    setPresentValue(user.address)
  }
   const onclick5 = () => {
     setCharacter("assetId")
    setPresentValue(user.assetId)
  }
   const onclick6 = () => {
     setCharacter("syndicate")
    setPresentValue(user.syndicate)
  }
   const onclick7= () => {
     setCharacter("number")
    setPresentValue(user.number)
  }
   const onclick8 = () => {
     setCharacter("userStatus")
    setPresentValue(user.userStatus)
  }
   const onclick9 = () => {
     setCharacter("password")
    setPresentValue("")
  }
  const submit = () => {
    const requestUrl = `/edit/update/head/${user.email}/${character}`;
  const headers = {
    'x-auth-token': `${token}`
    }
    const requestBody = {
      input:input
    }
     axios.post(requestUrl, requestBody,{ headers }).then(response => {
       console.log(response)
       removeUser()
       navigate("/syndicate-head")
      }).catch(errors => {
        if (errors.response.status === 500 || errors.response.status === 403) {
            console.log(errors)
            
         setError(errors);
        } else if (errors.response.status === 401 ) {
            navigate("/login")
        }
        else {
                setError(t('adm.erro'))
            }
    })
  }
  const onSumbit2 = () => {
       const requestUrl = `/edit/reset-password/head/${user.email}/`;
  const headers = {
    'x-auth-token': `${token}`
    }
    const requestBody = {
      password:input
    }
     axios.post(requestUrl, requestBody,{ headers }).then(response => {
       removeUser()
       navigate("/syndicate-head")
      }).catch(errors => {
        if (errors.response.status === 500 || errors.response.status === 403) {
         setError(errors);
        }else if (errors.response.status === 401 ) {
            navigate("/login")
        }
        else {
                setError(t('adm.erro'))
            }
    })
  }
  const formSub = (e) => {
    e.preventDefault()
  }
     const logout = () => {
    resetUserSession()
    navigate('/')
  }
  return (
    <>
      <div className="download__header">
            <img src={Logo} alt=" Data Corp Inc." onClick={()=>{navigate("/")}} className="logo"/>        <div className="user__name">
          <strong  className="admin__home" onClick={()=>{navigate("/syndicate-head")}}> {t('adm.hH')}</strong>
          <button onClick={logout}>LogOut</button>
        </div>
      </div>
       <div className='edit__page'>
      <div className="edit__upButtons">
        <button className='edit' onClick={onclickOne}>{t('adm.name')}</button>
        <button className='edit'  onClick={onclickTwo}>{t('adm.email')}</button>
        <button className='edit' onClick={onclickThree}>{t('adm.username')}</button>
        <button className='edit' onClick={onclick4}>{t('adm.address')}</button>
        <button className='edit' onClick={onclick5}>{t('adm.id')}</button>
        <button className='edit' onClick={onclick6}>{t('adm.syn')}</button>
        <button className='edit' onClick={onclick7}>{t('adm.number')}</button>
        <button className='edit' onClick={onclick8}>{t('adm.status')}</button>
        <button className='edit' onClick={onclick9}>{t('adm.password')}</button>
      </div>
      {character === "password" ? (
           <form action="" onSubmit={formSub}>
        <input type="text"  value={user.email} readOnly/>
     
        <input type="text" placeholder={t('adm.new')} defaultValue={input} onChange={e=>setInput(e.target.value) }/>
        <button type='submit' onClick={onSumbit2}>{t('adm.sub')}</button>
      </form>
      ) : (
          <form action="" onSubmit={formSub}>
        <input type="text"  value={user.email} readOnly/>
        {
          character !== "" ? (<span>
                  <strong>{t('adm.val')}</strong>
                  <strong>{ presentValue}</strong>
          </span> ):(null)
        }
     
        <input type="text" placeholder={t('adm.new')} defaultValue={input} onChange={e=>setInput(e.target.value) }/>
        <button type='submit' onClick={submit}>{t('adm.sub')}</button>
      </form>
      )}
       
    </div>
    </>
   
  )
}

export default EditHead