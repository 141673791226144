import React,{useState} from 'react';
import "../assets/styles/createProfile.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import {CreateAccount} from "../Components"

const registerUrl = "http://localhost:5000/auth/signup"

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    username: yup.string().required(),
    address: yup.string().required(),
    assetId: yup.string().length(10),
    syndicate: yup.number().required(),
    number: yup.string().required(),
    userStatus: yup.string().required(),
    password: yup.string().min(8).max(15).required(),
    group: yup.string().required(),
}).required();

function CreateProfile() {
    const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });
    const navigate = useNavigate();
    const [ message, setMessage ] = useState("");
 
   function createProfileSubmit(data){
       console.log(data);
       const { name, email, username, address, assetId, syndicate, number,
           userStatus, password, group } = data;
       const requestBody = {
           name: name,
           email: email,
           username: username,
           address: address,
           assetId: assetId,
           syndicate: syndicate,
           number: number,
           userStatus: userStatus,
           password: password,
           group:group
       }
       axios.post(registerUrl, requestBody).then(response =>
       { setMessage("Registration Successful")}
       ).catch(error => {
           if (error.response.statusCode === 401 || error.response.statusCode === 403) {
               setMessage(error.response.data.message);
           } else {
               setMessage('Sorry the backend server is done, please try again later')
            }
       })
    }
  
    
 
    return (
        <div className="create__accountPage">
            <CreateAccount/>
      </div>
    //   <div className='create_profilePage'>
    //         <div className="login_input">
    //           <label htmlFor="email">Email:</label>
    //           <input
    //               name='email'
    //               placeholder='Insert Email'
    //               {...register("email")}
    //       />
    //       </div>
    //           <>{errors.email?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="name">Name:</label>
    //           <input
    //               name='name'
    //               placeholder='Insert Full Name'
    //               {...register("name")}
    //       />
    //       </div>
    //           <>{errors.name?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="username">Username:</label>
    //           <input
    //               name='username'
    //               placeholder='Insert Username'
    //               {...register("username")}
    //       />
    //       </div>
    //           <>{errors.username?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="address">Address:</label>
    //           <input
    //               name='address'
    //               placeholder='Insert Address'
    //               {...register("address")}
    //       />
    //       </div>
    //           <>{errors.address?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="assetId">Asset ID:</label>
    //           <input
    //               name='assetId'
    //               placeholder='Insert Asset Id'
    //               {...register("assetId")}
    //       />
    //       </div>
    //           <>{errors.assetId?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="email">Syndicate:</label>
    //           <input
    //               name='syndicate'
    //               placeholder='Insert Syndicate'
    //               {...register("syndicate")}
    //       />
    //       </div>
    //           <>{errors.syndicate?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="number">Phone Number:</label>
    //           <input
    //               name='number'
    //               placeholder='Insert Phone Number'
    //               {...register("number")}
    //       />
    //       </div>
    //           <>{errors.number?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="email">Status:</label>
    //           <input
    //               name='userStatus'
    //               placeholder='Insert Status'
    //               {...register("userStatus")}
    //       />
    //       </div>
    //           <>{errors.status?.message}</>
    //       <div className="login_input">
    //           <label htmlFor="password">Password:</label>
    //           <input
    //               name='password'
    //               placeholder='Insert Password'
    //               {...register("password")}
    //       />
    //       </div>
    //           <>{errors.password?.message}</>
    //        <div className="login_input">
    //           <label htmlFor="group">Group:</label>
    //           <input
    //               name='group'
    //               placeholder='Insert Group'
    //               {...register("group")}
    //       />
    //       </div>
    //       <>{errors.group?.message}</>
    //       {message && <p>{ message}</p>}
    //       <button type="submit" onClick={handleSubmit(createProfileSubmit)}>Create Profile</button>
          
    // </div>
  )
}

export default CreateProfile