import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "../assets/styles/admin.css"
import { Footer } from '../Components';

const Unauthorized = () => {
  const { t } = useTranslation('common');
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
  return (
    <>
      <div className='unauthorized'>
        <div className="body">
           <h2>{t('adm.unauthorized')}</h2>
          <button onClick={goBack}> {t('adm.gob')}</button>
        </div>
   
    </div>
    <Footer/>
    </>

  )
}

export default Unauthorized