import React, {
  useState,
  useEffect
} from 'react';
import "../assets/styles/login.css";
import { useNavigate,Link,useLocation,} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from '../api/axios';
import { Download, AboutPics, Footer } from '../Components';
//import useAuth from '../hooks/useAuth';
import { setUserSession,getUser } from '../services/AuthService';
import { useTranslation } from "react-i18next";
import { Logo } from '../assets/images';

const loginUrl = "/auth/login";
const schema = yup.object({
    email: yup.string().email(),
    password: yup.string()
}).required();

function Login() {
    //const { auth, setAuth } = useAuth();
    //const location = useLocation();
    //const from = location.state?.from?.pathname || "/";
    const [ message, setMessage ] = useState("");
  const { t, i18n } = useTranslation('common');
  const user = getUser();
  const location = useLocation();
 const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate(-1)
    }
  })
      const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });
   
    const loginSubmit = (data) => {
        const { email, password } = data;
    
        const requestBody = {
            email: email,
            password:password
        }
        axios.post(loginUrl, requestBody).then(response => {
            setMessage("Login successful");
            //const groups = [];
            if (response.data.user.twoFactor || response.data.user.group === 'admin') {
                // groups.push(response.data.user.group);
                // setAuth({ ...response.data, groups })
                setUserSession(response.data.user,response?.data?.token)
                navigate('/verify');
            } else if (response.data.user.group[ 0 ] === 'Head') {
                // groups.push(response.data.user.group[ 0 ]);
                // setAuth({ ...response.data, groups })
                setUserSession(response.data.user,response.data.token)
                navigate('/syndicate-head')
            } else {
                // groups.push(response.data.user.group);
                // setAuth({ ...response.data, groups })
                setUserSession(response.data.user,response.data.token)
                navigate('/download-app');
                // navigate(from, { replace: true });
            }
            console.log(response)
            
           
        }).catch(errors => {
            console.log(errors)
            if (errors.response.status === 401 || errors.response.status === 403) {
          if (errors.response.data.errors[ 0 ].msg === "Email and password required") {
            setMessage(t('error.ep1'))
          } else if (errors.response.data.errors[ 0 ].msg === "Invalid Credentials") {
            setMessage(t('error.ep2'))
          }else if (errors.response.data.errors[ 0 ].msg === "User suspended") {
            setMessage(t('error.ep3'))
          }
            } else {
                setMessage(t('adm.erro'))
            }
        }

        )

    }

  return (
      <div className='login__page'>
          <div className="login__pageHeader">
            <img src={Logo} alt=" Data Corp Inc." onClick={()=>{navigate("/")}} className="logo"/>
              
          </div>
          <div className="login__pageBody">
              <div className="left__side">
                  <div className="left__sidePics">
                      <AboutPics/> 
                  </div>
                  <div className="left__sideInfo">
                    <Download />
                  </div>
              </div>
              <div className="right__side">
                  <h1>{ t('login.title')}</h1>
            <div className="login__input">
              <input
                          name='email'
                          type='text'
                  placeholder= { t('login.email')}
                  {...register("email")}
              />
             
          </div>
          <>{errors.email?.message}</>
        
          <div className="login__input">
              <input
                          name='password'
                          type='password'
                  placeholder={ t('login.password')}
                  {...register("password")}
              />
               
                  </div>
              
          <>{errors.password?.message}</>
          {message && <p>{ message}</p>}
                  <button type="submit" onClick={handleSubmit(loginSubmit)}>{t('login.btntxt')}</button>
                  <p onClick={() => { navigate("/forgot-password") }} className='reset__link'>{ t('adm.fpm')}</p>
              </div>
          </div>
         
       <Footer/>
    </div>
  )
}

export default Login