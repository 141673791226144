import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  CreateProfile,
  Home,
  EditUser,
  Login,
  VerifyAdmin,
  SyndicateHead,
  DownloadApp,
  Unauthorized,
  Admin,
  Missing,
  CreateAdmin,
  ComfirmationPage,
  EditHead,
  CreateHead,
  DetailPage,
  DetailHead,
  ForgotPassword,
  ResetPassword
} from "./pages";
import {RequireAuth,Footer,ChangeLanguage } from "./Components";


const GROUPS = {
  'Admin': 'admin',
  'Head': 'Head',
  'Customer':'customer'
}
function App() {
  return (
    <div className="App">
      <ChangeLanguage/>
      <Routes>
        {/* Public Routes */}
          <Route exact path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="download-app" element={<DownloadApp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/login/auth/reset-password/:id/:token" element={ <ResetPassword/>}/>
        
        {/* Protected Routes */}
        <Route element={<RequireAuth allowedRoles={GROUPS.Admin}/>}>
          <Route path="verify" element={<VerifyAdmin />} />
          <Route path="admin" element={<Admin />} />
          <Route path="create-admin" element={<CreateAdmin />} />
          <Route path="edit-user" element={<EditUser />} />
          <Route path="comfirmation-page" element={<ComfirmationPage />} />
          <Route path="detail-admin" element={ <DetailPage/>}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={ GROUPS.Head } />}>
        <Route path="create-profile" element={<CreateProfile />} />
          <Route path="syndicate-head" element={<SyndicateHead />} />
          <Route path="edit-head" element={<EditHead />} />
          <Route path="/create-head" element={<CreateHead />} />
          <Route path="/comfirmation-head" element={<ComfirmationPage />} />
          <Route path='/detail-head' element={ <DetailHead/>}/>
        </Route>

        <Route element={<RequireAuth allowedRoles={GROUPS.Customer } />}>
          
     </Route>
        
       
      
        {/* Catch all */}
        <Route path="missing" element={<Missing />} />
    
      </Routes>
    </div>
  );
}

export default App;
