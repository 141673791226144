import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import axios from '../api/axios';
import "../assets/styles/edit.css";
import { getOneUser,removeUser,getToken, resetUserSession } from '../services/AuthService';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';
import {Logo} from "../assets/images"


const EditUser = () => {
  const { t } = useTranslation('common');
  const [ character, setCharacter ] = useState("");
  const [ presentValue, setPresentValue ] = useState("Character Value");
  const [ input, setInput ] = useState();
  const [ error, setError ] = useState();
  const navigate = useNavigate();
  const token = getToken();
  const user = getOneUser()
  const onclickOne = () => {
     setCharacter("fullName")
    setPresentValue(user.fullName)
  }
  const onclickTwo = () => {
     setCharacter("email")
    setPresentValue(user.email)
  }
   const onclickThree = () => {
     setCharacter("username")
    setPresentValue(user.username)
  }
   const onclick4 = () => {
     setCharacter("address")
    setPresentValue(user.address)
  }
   const onclick5 = () => {
     setCharacter("assetId")
    setPresentValue(user.assetId)
  }
   const onclick6 = () => {
     setCharacter("syndicate")
    setPresentValue(user.syndicate)
  }
   const onclick7= () => {
     setCharacter("number")
    setPresentValue(user.number)
  }
   const onclick8 = () => {
     setCharacter("userStatus")
    setPresentValue(user.userStatus)
  }
   const onclick9 = () => {
     setCharacter("password")
    setPresentValue("")
  }
   const onclick10 = () => {
     setCharacter("group")
    setPresentValue(user.group)
  }
  const clickDelete = () => {
const requestUrl = `/edit/delete/${user.email}`;
  const headers = {
    'x-auth-token': `${token}`
    }
    
     axios.delete(requestUrl, { headers }).then(response => {
       removeUser()
       navigate("/admin")
      }).catch(errors => {
        if (errors.response.status === 500 || errors.response.status === 403) {
         setError(errors);
            } else {
                setError(t('adm.erro'))
            }
    })
  }
  const submit = () => {
    const requestUrl = `/edit/update/${user.email}/${character}`;
  const headers = {
    'x-auth-token': `${token}`
    }
    const requestBody = {
      input:input
    }
     axios.post(requestUrl, requestBody,{ headers }).then(response => {
       removeUser()
       navigate("/admin")
      }).catch(errors => {
        if (errors.response.status === 500 || errors.response.status === 403) {
         setError(errors);
        }else if (errors.response.status === 401 ) {
            navigate("/login")
        }
        else {
                setError(t('adm.erro'))
            }
    })
  }
  const onSumbit2 = () => {
       const requestUrl = `/edit/reset-password/${user.email}/`;
  const headers = {
    'x-auth-token': `${token}`
    }
    const requestBody = {
      password:input
    }
     axios.post(requestUrl, requestBody,{ headers }).then(response => {
       removeUser()
       navigate("/admin")
      }).catch(errors => {
        if (errors.response.status === 500 || errors.response.status === 403) {
         setError(errors);
        }else if (errors.response.status === 401 ) {
            navigate("/login")
        }
        else {
                setError(t('adm.erro'))
            }
    })
  }
  const formSub = (e) => {
    e.preventDefault()
  }
    const logout = () => {
    resetUserSession()
    navigate('/')
  }
  return (
    <>
      <div className="download__header">
            <img src={Logo} alt=" Data Corp Inc." onClick={()=>{navigate("/")}} className="logo"/>        <div className="user__name">
          <strong  className="admin__home" onClick={()=>{navigate("/admin")}}> {t('adm.adH')}</strong>
          <button onClick={logout}>{ t('home.navbar.logout')}</button>
        </div>
      </div>
       <div className='edit__page'>
     
      <div className="edit__upButtons">
        <button className='edit' onClick={onclickOne}>{t('adm.name')} </button>
        <button className='edit'  onClick={onclickTwo}>{t('adm.email')}</button>
        <button className='edit' onClick={onclickThree}>{t('adm.username')}</button>
        <button className='edit' onClick={onclick4}>{t('adm.address')}</button>
        <button className='edit' onClick={onclick5}>{t('adm.id')}</button>
        <button className='edit' onClick={onclick6}>{t('adm.syn')}</button>
        <button className='edit' onClick={onclick7}>{t('adm.number')}</button>
        <button className='edit' onClick={onclick8}>{t('adm.status')}</button>
        <button className='edit' onClick={onclick9}>{t('adm.password')}</button>
        <button className='edit' onClick={onclick10}>{t('adm.grp')}</button>
         
      </div>
        <Popup trigger={<button className='delete'> {t('adm.del')}</button>} position="bottom center">
          { close =>(<div className='delete__popup'>
          <h1>{t('adm.info')}</h1>
          <div className="delete__body">
              <span>
              <small>{t('adm.name')}: </small>
              <strong>{ user.fullName}</strong>
            </span>
             <span>
              <small>{t('adm.email')}: </small>
              <strong>{ user.email}</strong>
            </span>
             <span>
              <small>{t('adm.username')}: </small>
              <strong>{ user.username}</strong>
            </span>
             <span>
              <small>{t('adm.id')}: </small>
              <strong>{ user.assetId}</strong>
            </span>
             <span>
              <small>{t('adm.syn')}: </small>
              <strong>{ user.syndicate}</strong>
            </span>
             <span>
              <small>{t('adm.number')}:</small>
              <strong>{ user.number}</strong>
            </span>
             <span>
              <small>{t('adm.status')}: </small>
              <strong>{ user.userStatus}</strong>
            </span>
             <span>
              <small>{t('adm.grp')}: </small>
              <strong>{ user.group}</strong>
            </span>
          </div>
            
            <h5> {t('adm.comf')}</h5>
          <div className="popup__buttons">
             <button className='no close' onClick={close}>{t('adm.no')}</button>
          
              <button className='yes' onClick={clickDelete}>{t('adm.yes')}</button>
          </div>
          
              </div>)}
          </Popup>
      {character === "password" ? (
           <form action="" onSubmit={formSub}>
        <input type="text"  value={user.email} readOnly/>
     
        <input type="text" placeholder={t('adm.new')} defaultValue={input} onChange={e=>setInput(e.target.value) }/>
        <button type='submit' onClick={onSumbit2}>{t('adm.sub')}</button>
      </form>
      ) : (
          <form action="" onSubmit={formSub}>
        <input type="text"  value={user.email} readOnly/>
        {
          character !== "" ? (<span>
            <small>{t('adm.val')} </small>
            <strong>{ presentValue}</strong>
          </span> ):(null)
        }
     
        <input type="text" placeholder={t('adm.new')} defaultValue={input} onChange={e=>setInput(e.target.value) }/>
        <button type='submit' onClick={submit}>{t('adm.sub')}</button>
      </form>
      )}
       
    </div>
    </>
   
  )
}

export default EditUser