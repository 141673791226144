import React,{useState,useEffect} from 'react';
import axios from "../../api/axios";
import { getUser,getToken} from '../../services/AuthService';
import BikeInfo from "../BikeInfo";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Loading from '../loading';

function Disabled({detailPage}) {
  const navigate = useNavigate();
  const {t} = useTranslation('common');
  const [ page, setPage ] = useState(1);
  const [ data, setData ] = useState();
  const [ pageTitle, setPageTitle ] = useState("");
  const [ paginate2, setPaginate2 ] = useState(true);
  const [ syndicate, setSyndicate ] = useState("");
  const [pageCount, setPageCount] = useState()
  const [ onHead, setOnHead ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const user = getUser();
  const token = getToken();
 
  useEffect(() => {
   const onclick01 = () => {
   
    const requestSideUrl = `/profiles/search-admin/disabled?page=${page}&limit=12`;
  const headers = {
    'x-auth-token': `${token}`
    }
     if (user) {
    axios.get(requestSideUrl,{ headers}).then(response => {
      setData(response.data)
      setLoading(false)
      setPaginate2(true)
       setPageCount(response.data.pageCount.pageCount)
      //setAllData(response.data)
      setPageTitle(t('adm.disT'))
      setSyndicate("")
      setOnHead(true)
    }).catch(errors => {
      setLoading(false)
       if (errors.response.status === 401 || errors.response.status === 403) {
          if (errors.response.data.errors[ 0 ].msg === "Invalid Token" || errors.response.data.errors[ 0 ].msg === "No token found") {
                      navigate('/login')
        } else {
            setPageTitle(t('error.noU2'));
            setPaginate2(false)
         }
         setData("")
            } else {
         setPageTitle(t('adm.erro'))
             setData("")
         setPaginate2(false)
            }
    })
  }
      }
      onclick01()
  }, [ page ])

  const pageNumbers = [];

 

  const synSubmit = () => {
      const requestUrl = `/profiles/search-admin/disabled/${syndicate}?page=${page}&limit=12`;
      const headers = {
        'x-auth-token': `${token}`
    }
    setLoading(true)
    
       axios.get(requestUrl, { headers }).then(response => {
         setData(response.data)
         setPaginate2(true)
         setLoading(false)
        //setAllData(response.data)
        setPageTitle( t('adm.mess2')+` ${syndicate} `)
        setSyndicate("")
       }).catch(errors => {
         setPaginate2(false)
         setLoading(false)
         setData("")
         setSyndicate("")
       if (errors.response.status === 401 || errors.response.status === 403) {
          if (errors.response.data.errors[ 0 ].msg === "Invalid Token" || errors.response.data.errors[ 0 ].msg === "No token found") {
                      navigate('/login')
        } else {
            setPageTitle(t('error.noU2'));
         }
       
            } else {
                setPageTitle(t('adm.erro'))
            }
    })
    
  }
  const formSubmit2 = (e) => {
    e.preventDefault()
  }
     for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }
const paginate = pageNumber => {

    setPage(pageNumber)
  };
 if (loading) {
     return (
      <div className='admin__loading'>
      <Loading />

      </div>)
  }
  return (
 
      <div className="admin__content">
           <div className="create__user">
        </div>
          {pageTitle && (
            <div className='content__header'>
            <h3>{pageTitle}</h3>
            {
                onHead && (
                  <div className='syn__input'>
                <small>{ t('adm.synNo')}</small>
                  <form className="input" onSubmit={formSubmit2}>
                  <input type="text" value={syndicate} onChange={e=> setSyndicate(e.target.value) }/>
                  <button type='submit' onClick={synSubmit}></button>
                  </form>
                  </div>
                 
     
            )
            }
            </div >
      
          )}
          <div className="admin__contentColumn">
            {
              data&& (
                 data?.results?.map((item, index) => {
                return (
                  <BikeInfo
                    name={item.username}
                    syndicate={item.syndicate}
                    email={item.email}
                        assetId={item.assetId}
                          
                  />
                )
              })
              ) 
             
          }
      </div>
      {
        paginate2 && (
        <div className="page__number">
               <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <p onClick={() => paginate(number)} className='page-link'>
              {number}
            </p>
          </li>
        ))}
      </ul>
    </nav>
          </div>
        )
      }
  
    </div>

 

        
  )
}

export default Disabled