import React from 'react';
import { getUser, resetUserSession } from "../services/AuthService";
import { useNavigate } from "react-router-dom";

function HomePageAccess() {
  const navigate = useNavigate();
  const user = getUser();
  const name = user !== 'undefined' && user ? user.name : '';
  const logoutButton = () => {
    resetUserSession();
    navigate('/')
  }
  return (
    <div>
      <h1>Home Page Access</h1>
      <h3>Hello {name} You are authorised to be on this page</h3>
      <button type="submit" onClick={logoutButton}>Logout</button>
    </div>
  )
}

export default HomePageAccess