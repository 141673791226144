import React from 'react';
import "../assets/styles/admin.css";
import { MdElectricBike } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { setOneUser, getUser } from '../services/AuthService';
import { useTranslation } from "react-i18next";

const BikeDetails = ({username,address,email,assetId,link,fullName,syndicate,userStatus,group,number,date}) => {
  const navigate = useNavigate();
    const user = {
    email,assetId,syndicate,userStatus,group,number,fullName,username,address
  }
   const {t} = useTranslation('common');
  const activeUser= getUser()
  const editButton = () => {
       navigate("/edit-user")
   
    setOneUser(user)
  }
   const editButton2 = () => {
       navigate("/edit-head")
   
    setOneUser(user)
  }
  const detailPage = () => {
    navigate("/detail-admin")
     setOneUser(user)
  }
  const detailHead = () => {
    navigate("/detail-head")
     setOneUser(user)
}
  return (
    <div className='admin__bikeDetail'>
      
      <button className='edit__button' onClick={
        activeUser.group === "admin"?(
        editButton
        ) : (
            editButton2
        )
        }>{ t('adm.ed')}</button>
      <MdElectricBike size={50} color='#000' className='bike__icon'/>
      <div className="admin__bikeTitle">
        <h3>{username}</h3>
      </div>
      <div className="admin__bikeDetailBody">
          <span>
          <strong>{t('adm.date')} </strong>
          <strong> |</strong>
        <small> { date}</small>
      </span>
          <span>
          <strong>{t('adm.address')} </strong>
           <strong> |</strong>
        <small>  { address}</small>
      </span>
      <span>
          <strong>{t('adm.email')} </strong>
           <strong> |</strong>
        <small>  { email}</small>
      </span>
      <span>
          <strong>{t('adm.id')} </strong>
          <strong> |</strong>
        <small> { assetId}</small>
      </span>
      <span>
          <strong>{t('adm.syn')} </strong>
           <strong> |</strong>
        <small> { syndicate}</small>
        </span>
         <div className="track__bike">
        <button  onClick={
        activeUser.group === "admin"?(
        detailPage
        ) : (
            detailHead
        )
        }>
   
            <strong>{ t('adm.loc')}</strong>

     
        </button>
      </div>
      </div>
     
    </div>
  )
}

export default BikeDetails