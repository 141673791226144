import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../assets/styles/change.css";

const ChangeLanguage = () => {
    const [ t, i18n ] = useTranslation('common');
    const [ fr, setFr ] = useState(true);
    const [ en, setEn ] = useState(false);
    const [ name, setName ] = useState("En")
    const change = () => {
       
        if (fr) {
            i18n.changeLanguage('en')
            setEn(true);
            setName("Fr");
            setFr(false); 
        } else {
             i18n.changeLanguage('fr')
            setEn(false);
            setName("En");
            setFr(true);
        }
       
    }
    
  return (
      <div className='change'>
          <button onClick={change}>{ name}</button>
    </div>
  )
}

export default ChangeLanguage