import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../api/98635-loading.json';
import "../assets/styles/admin.css"


const Loading = () => {
  return (
      <div className='loading'>
           <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
    </div>
  )
}

export default Loading