import React,{useState,useEffect} from 'react';
import axios from "../api/axios";
import { getUser,getToken } from '../services/AuthService';
import {  BikeDetails,Loading} from "../Components";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next"


function Admin() {
  const navigate = useNavigate();
  const {t} = useTranslation('common')
  const [ page, setPage ] = useState(1);
  const [ data, setData ] = useState();
  const [ pageTitle, setPageTitle ] = useState("")
  const [ syndicate, setSyndicate ] = useState("");
  const [ paginate2, setPaginate2 ] = useState(true);
  const [pageCount, setPageCount] = useState()
  const [ onHead, setOnHead ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const user = getUser();
  const token = getToken();
 
  useEffect(() => {
   const onclick8 = () => {
   const requestUrl = `/profiles/search-admin/admin?page=${page}&limit=12`;
  const headers = {
    'x-auth-token': `${token}`
    }
     if (user) {
       axios.get(requestUrl, { headers }).then(response => {
         setData(response.data)
         setLoading(false)
      //setAllData(response.data)
         setPageTitle(t('adm.aAdm'))
         setPaginate2(true)
         setPageCount(response.data.pageCount.pageCount)
      setSyndicate("")
      setOnHead(true)
       }).catch(errors => {
         setLoading(false)
         setData("")
         setPaginate2(false)
       if (errors.response.status === 401 || errors.response.status === 403) {
       if (errors.response.data.errors[ 0 ].msg === "Invalid Token" || errors.response.data.errors[ 0 ].msg === "No token found") {
                      navigate('/login')
        } else {
            setPageTitle(t('error.noU2'));
           
         }
            } else {
                setPageTitle(t('adm.erro'))
            }
    })
  }
      }
      onclick8()
  }, [ page ])



  const synSubmit = () => {
      const requestUrl = `/profiles/search-admin/admin/${syndicate}?page=${page}&limit=12`;
      const headers = {
        'x-auth-token': `${token}`
      }
       axios.get(requestUrl, { headers }).then(response => {
         setData(response.data)
      setPaginate2(true)
         setLoading(false)
        //setAllData(response.data)
        setPageTitle(`Admins | ${syndicate} `)
        setSyndicate("")
       }).catch(errors => {
         setLoading(false);
         setPaginate2(false)
         setData("")
       if (errors.response.status === 401 || errors.response.status === 403) {
         if (errors.response.data.errors[ 0 ].msg === "Invalid Token" || errors.response.data.errors[ 0 ].msg === "No token found") {
                      navigate('/login')
        } else {
            setPageTitle(t('error.noU2'));
           
         }
            } else {
                setPageTitle(t('adm.erro'))
            }
    })
    
  }
  const formSubmit2 = (e) => {
    e.preventDefault()
  }
const pageNumbers = []; 
      for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const paginate = pageNumber => {
 
    setPage(pageNumber)
  };
  if (loading) {
    return (
      <div className='admin__loading2'>
      <Loading />

      </div>)
  }

  return (
        <div className="admin__content">
           <div className="create__user">
        <button onClick={() => navigate('/create-admin')}>{ t('adm.create')}</button>
        </div>
          {pageTitle && (
            <div className='content__header'>
            <h3>{pageTitle}</h3>
            {
                onHead && (
                  <div className='syn__input'>
                  <small>Enter Syndicate No :</small>
                  <form className="input" onSubmit={formSubmit2}>
                  <input type="text" value={syndicate} onChange={e=> setSyndicate(e.target.value) }/>
                  <button type='submit' onClick={synSubmit}></button>
                  </form>
                  </div>
                 
     
            )
            }
            </div >
      
          )}
          <div className="admin__contentRow">
            {
              data&& (
                 data?.results?.map((item, index) => {
                return (
                  <BikeDetails
                    username={item.username}
                    address={item.address}
                    syndicate={item.syndicate}
                    email={item.email}
                    assetId={item.assetId}
                    fullName={item.fullName}
                    userStatus={item.userStatus}
                    group={item.group}
                    number={item.number}
                    date={item?.date }
                  />
                )
              })
              ) 
             
          }
      </div>
      {
        paginate2 && (
      <div className="page__number">
               <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <p onClick={() => paginate(number)} className='page-link'>
              {number}
            </p>
          </li>
        ))}
      </ul>
    </nav>
          </div>
        )
      }
    
        </div>
 
  )
}

export default Admin