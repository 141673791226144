import React from 'react';
import "../assets/styles/footer.css";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t, i18n} = useTranslation('common');
  return (
      <div className="footer">
          {/* <div className="footer__body">
        <div className="footer__bodySect">
          <a href="/#About"><p>{ t('footer.about')}</p></a>
            <a href="/#contact">  <p>{ t('footer.contact')}</p></a>      
                 
          
              </div>
                      <div className="footer__bodySect">
                      <a href="/#About"><p>{ t('footer.about')}</p></a>
            <a href="/#contact">  <p>{ t('footer.contact')}</p></a> 
            
              </div>
                       <div className="footer__bodySect">
                    <a href="/#About"><p>{ t('footer.about')}</p></a>
            <a href="/#contact">  <p>{ t('footer.contact')}</p></a> 
       
            </div>
          </div> */}
          <div className="footer__copyright">
            <p>{ t('footer.copyright')}</p>
          </div>
    </div>
  )
}

export default Footer