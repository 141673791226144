import React, { useEffect,useState } from 'react';
import axios from '../api/axios';
import { useParams } from 'react-router-dom';
import "../assets/styles/change.css"
import { Footer } from '../Components';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate()
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ login, setLogin ] = useState(false);
    const params = useParams();
    const id = params.id
    const token = params.token
    console.log(params)
    useEffect(() => {
        const requestUrl = `/auth/reset-password/${id}/${token}`
        axios.get(requestUrl).then(response => {
            console.log(response)
            setEmail(response.data.emal)
            }).catch(errors => {
                if (errors.response.status === 401 || errors.response.status === 403) {
                    if (errors.response.data.errors[ 0 ].msg === "Invalid Token") {
                              setMessage(t('error.inT'))
                    } else if (errors.response.data.errors[ 0 ].msg === "No such user exist") {
                        setMessage(t('error.noU'))
                }
  
            } else {
                setMessage(t('adm.erro'))
            }
        })
    })
    const onSubmit = () => {
        const changeUrl = `/auth/reset-password/${id}/${token}`
        const requestBody = {
            password:password
        }
        axios.post(changeUrl, requestBody).then(response => {
            setMessage(t('adm.rM'))
            setLogin(true)
        }).catch(errors => {
            if (errors.response.status === 401 || errors.response.status === 403) {
                if (errors.response.data.errors[ 0 ].msg === "Invalid Token") {
                              setMessage(t('error.inT'))
                    } else if (errors.response.data.errors[ 0 ].msg === "No such user exist") {
                        setMessage(t('error.noU'))
                } else {
                    setMessage(t('error.inT'))
                }
            } else {
               setMessage(t('adm.erro'))
            }
        })
    }
    const formSubmit = (e) => {
        e.preventDefault()
    }
  return (
      <div className='reset__password'>
          <div className="reset__content">
              <h3>{t('adm.rp') }</h3>
          <form onSubmit={formSubmit}>
        <input type="text" value={email} readOnly/>
          <input type='text' placeholder={t('adm.np')} defaultValue={password}
          onChange={e=>{setPassword(e.target.value)}}
          />
                  <button type='submit' onClick={onSubmit}>{ t('adm.cp')}</button>
                  <p>{message}</p>
                  {login && (
                      <p onClick={()=>{navigate("/login")}}>{ t('home.navbar.login')}</p>
                  )}
                 
          </form>
          </div>
       

      <Footer/>
   
    </div>
  )
}

export default ResetPassword