import React,{useState} from 'react';
import { resetUserSession, getTerm ,setTerm1} from '../services/AuthService';
import { AdminHeader, AdminSidebar,Head,Admin1, Customer1, Enabled1,Disabled1, Search1, AllUsers,Footer} from "../Components";
import { useNavigate } from 'react-router-dom';


function Admin() {
  const navigate = useNavigate();
  const [ term, setTerm ] = useState("");
  const [ head, setHead ] = useState(false);
  const[click,setClick]= useState(true)
  const [ admin, setAdmin ] = useState(false);
  const [ customer, setCustomer ] = useState(false);
  const [ enabled, setEnabled ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);
  const [ search, setSearch ] = useState(false)
  const [ allUsers, setAllUsers ] = useState(true);
  const previousTerm = getTerm()
  const onclick = () => {
    setHead(false)
    setAdmin(false)
    setCustomer(false)
    setEnabled(false)
    setDisabled(false)
    setSearch(false) 
    setAllUsers(true)
   }
  const Onclick1 = () => {
    setHead(true)
    setAdmin(false)
    setCustomer(false)
    setEnabled(false)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
  }
  const searchSubmit = () => {
       setDisabled(false)
     setHead(false)
    setAdmin(false)
    setCustomer(false)
    setEnabled(false)
    setSearch(true)
    setAllUsers(false)
    setTerm1(term)
    setClick(!click)
    
  } 
 
  const onclick8 = () => {
    setAdmin(true)
    setHead(false)
    setCustomer(false)
    setEnabled(false)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
  }
  const onclick6 = () => {
    setCustomer(true)
    setAdmin(false)
    setHead(false)
    setEnabled(false)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
   }
  const onclick4 = () => {
    setDisabled(true)
    setHead(false)
    setAdmin(false)
    setCustomer(false)
    setEnabled(false)
    setSearch(false)
    setAllUsers(false)
  }
  const onclick01 = () => {
    setEnabled(true)
     setCustomer(false)
    setAdmin(false)
    setHead(false)
    setDisabled(false)
    setSearch(false)
    setAllUsers(false)
  
  }
    const logoutButton = () => {
    resetUserSession();
    navigate('/')
  }
console.log(term)
  return (
    <>
      <div className='admin__page'>
 <div className="admin__header">
        <AdminHeader
          term={term}
          setTerm={setTerm}
          searchSubmit={searchSubmit}
        />
      </div>
      <div className="admin__body">
       
        <div className="admin__sidebar">
          <AdminSidebar
            onClick3={onclick}
            onClick2={Onclick1}
            onClick8={onclick8}
            onClick6={onclick6}
            onClick4={onclick4}
            onClick1={onclick01}
            onClick7 = {logoutButton}
          />
        </div>
        { head && (
          <Head/>
        )}
        {admin && (
          <Admin1/>
        )}
        {
          customer && (
            <Customer1/>
          )
        }
        {
          enabled && (
            <Enabled1/>
          )
        }
        {
          disabled && (
            <Disabled1/>
          )
        }
        {
          (search && term !== "") &&(
            <Search1
            term={term}
            />
          ) 
        }
        {
           search  && term === "" && (
            <Search1
              term={previousTerm}
            />
          )
        }
      
        {
          allUsers && (
            <AllUsers/>
          )
        }
          </div>
        
           


     
      </div>
    
   <div className="admin__footer">
          </div>
    </>
   
  )
}

export default Admin