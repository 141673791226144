import React from 'react';
import { Download, Footer } from '../Components';
import { getUser, resetUserSession } from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import "../assets/styles/herosection.css";
import { apple, google } from "../assets/images";
import { useTranslation } from 'react-i18next';
import {Logo} from "../assets/images"

const DownloadApp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const user = getUser()
  const userName = user?.fullName;
  const logout = () => {
    resetUserSession()
    navigate('/')
  }
  return (
    <>
          <div className='download__app'>
        <div className="download__header">
               <img src={Logo} alt=" Data Corp Inc." onClick={()=>{navigate("/")}} className="logo"/>
        <div className="user__name">
            <strong>{userName}</strong>
            {
              user ? (
                <button onClick={logout}>{t('home.navbar.logout') }</button>
              ) : (
                   <button onClick={()=>navigate('/login')}>{t('home.navbar.login')}</button>
              )
            }
 
         
        </div>
      </div>
      <div className="download__appBody">
           <div className="hero__sectionBody">
                  <div className="hero__sectionInfo">
                  <h5>{t('download.title')}</h5>
                  <small>{t('download.tagline')}</small>
                  <div className="hero__sectionInfoButtons">
                      <button className='one'><img src={google} alt="" /></button>
                       <button className='two'><img src={apple} alt="" /></button>
                </div>
              </div>
      </div>
      </div>
      
    </div>
    <div className="download__footer">
        <Footer/>
      </div>
    </>

  )
}

export default DownloadApp