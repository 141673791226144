import React from 'react';
import "../assets/styles/howto.css";
import { map ,dlamap} from "../assets/images";
import {useTranslation} from "react-i18next";

const Howto = () => {
  const {t, i18n} = useTranslation('common');
    return (
        <>
        <div className="howto" id='howTo'>
          <h1>{ t('home.howto.title')}</h1>
          <div className="howto__body">
            <div className="howto__stepOne">
              <h1>01</h1>
              <h3>{ t('home.howto.1')}</h3>
              <p>{ t('home.howto.1Body')}</p>
          </div>
          <div className="howto__stepTwo">
                   <h1>02</h1>
              <h3>{ t('home.howto.2')}</h3>
              <p>{ t('home.howto.2Body')}</p>
          </div>
          <div className="howto__stepOne">
                   <h1>03</h1>
              <h3>{ t('home.howto.3')}</h3>
              <p>{ t('home.howto.3Body')}</p>
          </div>
          </div>
      </div>
      <div className="after__howto">
            <img src={dlamap} alt="" />  
      </div>
      </>

  )
}

export default Howto