import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
//import useAuth from "../hooks/useAuth";
import { getUser } from '../services/AuthService';
export const RequireAuth = ({allowedRoles}) => {
    //const { auth } = useAuth();
   const location = useLocation();
   const user = getUser()
  return (
      user?.group === allowedRoles || user?.group[0] === allowedRoles
          ? <Outlet />
          : user
              ? <Navigate to='/unauthorized' state={{ from:location}} replace/>
           : <Navigate to='/login' state={{ from: location }} replace />
  )
}


export default RequireAuth;