import React from 'react';
import "../assets/styles/about.css";
import {lady1,lady2,lady3,town,bikes} from "../assets/images";

const AboutPics = () => {
    return (
      <div className="about">
           <div className="about__right">
              <img src={bikes} alt="" className='image__4' />
              <img src={lady1} alt="" className='image__1' />
              <img src={lady2} alt="" className='image__2' />
              <img src={lady3} alt="" className='image__3'/>
          </div>  
      </div>

  )
}

export default AboutPics